import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';

import logo from 'img/companyLogo/sentry.svg';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Sentry?</SubTitle>
      <p>
        Sentry is a bug tracking tool that helps you find issues and errors on your website. This
        way, it’s easier and faster to ship high-quality software. Our integration will help you
        improve the user experience on the website. Integrating Sentry with LiveSession is a real
        time-saver, as you can access <strong>all information in one place</strong>.
      </p>
      <SubTitle className="mt-5">How does it work?</SubTitle>
      <p>
        As you connect both tools, each new issue in Sentry will get a tag called <i>sessionURL</i>.
        It will redirect you to the right recording in LiveSession so you can immediately see what
        caused the error.
      </p>
      <p>
        Every time a new session will start, our code will send an event to Segment with a link to
        session replay.
      </p>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        <strong>Important</strong>: Remember that LiveSession only starts recording if it detects
        activity of user (
        <a
          href="https://developers.livesession.io/javascript-api/recording-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          find out more
        </a>{' '}
        informations about recording conditions). Because of this, Sentry will wait 3 seconds after
        initializing. If the user doesn’t do anything for more than 3 seconds, the <i>sessionURL</i>{' '}
        tag will be empty.
      </div>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy lang="js">
        {`<script type="text/javascript">
Sentry.init({
  dsn: "YOUR_SENTRY_URL",
  beforeSend: function beforeSend(event) {
    var createdSessionURL = "";

    __ls("getSessionURL", function (url, isNewSession) {
        createdSessionURL = url;
    });

    var wait = function wait(ms) {
      return new Promise(function (r, j) {
        return setTimeout(r, ms);
      });
    };

    return wait(3000).then(function () {
      event.extra["sessionURL"] = createdSessionURL;
      return event;
    });
  }
});
Sentry.configureScope(function (scope) {
  scope.setExtra("sessionURL", "");
});
</script>
`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration works you need to have Sentry and LiveSession installed on your
        website. If you haven&apos;t installed our tracking code yet check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Sentry integration',
  canonical: '/help/sentry-integration/',
  metaDescription: 'Add LiveSession recordings to issues in Sentry.',
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
